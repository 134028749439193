.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* Adjust this value if you have other elements that should appear above or below the sticky element */
  background-color: white;
}
.no-transition .collapse {
  transition: none !important;
}




/* Sliding drawer styles */
.nav-drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  background-color: white;
  box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1050; /* Ensure it appears on top of other elements */
  padding: 1rem;
}

.nav-drawer.open {
  transform: translateX(0);
}

.nav-drawer .nav-link {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.nav-drawer .close-btn {
  font-size: 1.5rem;
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  display: none;
}

.backdrop.open {
  display: block;
}




/* --------------------------------NavTop------------------------------------------- */

.navtop_main_div {
  background-color: #065f46;
  padding: 0.5625rem 1rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.navtop_main_div p {
  color: var(--Tailwind-white, #fff);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.015rem;
}

.top_nav_icon {
  color: white;
  margin-right: 0.3rem;
}

/* --------------------------------NavBar------------------------------------------- */

.navbar_main_div {
  padding: 0 1rem !important;
   
}

.time_icon{
  font-size: 1rem;
 
}
.topnav__{
  padding-top: 2rem;
}

.topnav__ h3{
  color: var(--Tailwind-slate-950, #020617);
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.topnav__ p{
  color: var(--Tailwind-slate-950, #020617);
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.015rem;

}

.list__ {
  text-decoration: none;
  color: #000;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 500; 
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.nav_btn p {
  color: var(--Tailwind-black, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.nav_btn {
  border-radius: 0.5rem;
  background: var(--Tailwind-yellow-400, #facc15);
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  height: 2.5rem;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}


/* --------------------------------carousel------------------------------------------- */

.carousel .control-dots {
  display: flex;
  justify-content: end;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.carousel .control-dots .dot {
  margin: 0 5px;
}

.carousel-container {
  padding: 0 0.5rem;

}

.carousel-container__cr4  {
  padding: 0;

  
}

.carousel-container__cr4 .carousel {
  border-radius: 0 0.7rem 0.7rem 0;
  
}

.carousel {
  border-radius: 0.7rem;
}



/* --------------------------------About_STA------------------------------------------- */

.about_sta_main_div {
  margin: 0 1rem;
  border: 2px solid #E2E8F0;
  border-radius: 0.7rem;

}

.about_nav_btn p {
  color: var(--Tailwind-black, #000);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}
.abou_sta_ico{
  font-size:1.25rem;
  font-weight: 900;
  
}

.about_nav_btn {
  border-radius: 0.5rem;
  background: var(--Tailwind-yellow-400, #FACC15);
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  height: 3.2rem;
  padding: 0.625rem 2rem 0.625rem 1.75rem;
  align-items: center;
  gap: 0.625rem;
  margin-top: 1.5rem;
  width: 45%;
}

.about_sta_main_div h2 {
  color: #000;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.04rem;
}

.about_sta_main_div h1 {
  color: #000;
  font-family: Inter;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.05rem;
}

.about_sta_main_div h4 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 350;
  line-height: 2rem;
  /* 160% */
}

.about_sta {
  padding:9rem 6.25rem;
  background-image: url('./assets/bgimage.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}


/* ----------------------------------Middlebar--------------------------------*/
.middlebar {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.horizontal_scroll_container_middlebar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  animation: scroll 20s linear infinite;

}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}

.middlebar_contant ul {
  padding-top: 9px;
  padding-bottom: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image_div_middlebar {
  background: var(--Tailwind-gray-100, #F3F4F6);
  border-radius: 50%;

}

.vertical-line {
  border-left: 2px solid #CBD5E1;
  ;
  /* Adjust color and width */
  height: 3rem;
  /* Adjust height to match the content */
}

.middlebar_product_qt h4 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.middlebar_product_qt h5 {
  color: var(--Tailwind-slate-600, #475569);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 0;
}



/*--------------------Best selling products------------------------------*/

.best_selling_div_list ul li {

  padding: 0.625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #FFD5D5;
  margin-right: 1rem;
  color: var(--Tailwind-red-700, #B91C1C);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.0175rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.best_selling_div_list ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  
}

.organic_apple_main_div {
  padding: 2.5rem 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #FFE5E5;
}


.organic_apple_main_div h3 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0.5rem;
}


.div_price h1 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Inter;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.div_price h2 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.show-more {

  color: var(--Tailwind-slate-950, #020617);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5625rem;
  margin: 0;
}

.read-more u {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5625rem;
  text-decoration-line: underline;

}

.read-more {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.best_selling_div_list_bread ul li{
  color: var(--Tailwind-orange-700, #C2410C);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.0175rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.best_selling_div_list_bread ul li {

  padding: 0.625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #FEE4C1;
  margin-right: 1rem;

}

.best_selling_div_list_bread ul {
  
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}




.best_selling_div_list_coffe ul li{
  color: var(--Tailwind-orange-700, #933B18);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.0175rem;
}

.best_selling_div_list_coffe ul li {

  padding: 0.625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #FFE0D7;
  margin-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

}

.best_selling_div_list_coffe ul {

  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.bread_main_div {
  padding: 2.5rem 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.5rem;
background: #FFF3E8;

}


.bread_main_div h3 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0.5rem;
}

.coffe_main_div {
  padding: 2.5rem 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.5rem;
background: #FFECE7;

}


.coffe_main_div h3 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0.5rem;
}

.our_product_div{
  border: 1px solid #E2E8F0;;
   
}
.our_product_sub_div h1{
  color: var(--Tailwind-slate-950, #020617);
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.our_product_sub_div h1{
  color: var(--Tailwind-slate-950, #020617);
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.rating_start{
  color: orange;
  font-size: 1.25rem;
}

.price_div_ourproduct h2{
  color: var(--Tailwind-slate-950, #020617);
 
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;

}

.price_div_ourproduct h3{
  color: var(--Tailwind-slate-950, #020617);

font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;

}

.throwline {
  border-bottom: 2px solid #E2E8F0;
}

.our_product_main_div{
  display: flex;
  flex-wrap: wrap;
}

.our_product_head_div h1{
  color: #000;
  font-family: Inter;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.045rem;
  
}
.oru_product__{
  padding: 0 1rem;
}

.offer_egg_main_div{
  border-radius: 0.75rem;
background: linear-gradient(285deg, #044A36 0.75%, rgba(4, 120, 87, 0.85) 99.06%);
box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04);
}
.fire_div p{
  color: var(--Tailwind-amber-300, #FCD34D);
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.offer_egg_main_div h2{
  color: var(--Tailwind-white, #FFF);
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 600;
line-height: 1.75rem; /* 140% */
letter-spacing: -0.0125rem;
}

.offer_egg_main_div h3{
  color: var(--Tailwind-white, #FFF);
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.offer_nav_btn p {
  color: var(--Tailwind-black, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.offer_nav_btn {
  border-radius: 0.5rem;
  background: var(--Tailwind-yellow-400, white);
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  height: 2.5rem;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}

.offer_juce_main_div{
  border-radius: 0.75rem;
  background: linear-gradient(286deg, #EA580C 1.4%, #DD8400 98.81%);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04);
}


.offer_juce_main_div h2{
  color: var(--Tailwind-white, #FFF);
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 600;
line-height: 1.75rem; /* 140% */
letter-spacing: -0.0125rem;
}

.offer_juce_main_div h3{
  color: var(--Tailwind-white, #FFF);
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.offer_bread_main_div{
  border-radius: 0.75rem;
background: linear-gradient(285deg, #0369A1 -0.85%, #003856 100%);
box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04);
}


.offer_bread_main_div h2{
  color: var(--Tailwind-white, #FFF);
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 600;
line-height: 1.75rem; /* 140% */
letter-spacing: -0.0125rem;
}

.offer_bread_main_div h3{
  color: var(--Tailwind-white, #FFF);
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.offer_div__{
  padding: 0 1rem;
}

.best_selling_product___{
  padding: 0 1rem;
}
.map_div_main{
   padding: 0 1rem;
}
.contact_icon_div{
  background-color: #ECFDF5;
  border-radius: 50%;
  padding: 0.8rem;
  font-size:1.3rem;
  /*------------------------------------------------*/
  display: flex;
    align-items: center;
    justify-content: center; 

  /*------------------------------------------------*/
}

.contact_form_div{
  margin: 0 1rem;
  background-image: url('./assets/contact_form_vector.png');
  border-radius: 0.75rem;
border: 1px solid var(--Tailwind-gray-200, #E5E7EB);
box-shadow: 6px -6px 12px 0px rgba(101, 101, 101, 0.04) inset, -6px 6px 12px 0px rgba(101, 101, 101, 0.04) inset;
}

.contact_form_icons_div {
  padding: 10rem;
  }
.content_form h1{
  color: var(--Tailwind-slate-600, #475569);
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.0175rem;
}

.content_form h2{
  color: var(--Tailwind-slate-950, #020617);
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: normal;
} 
.icon___{
  color: #047857;
  font-size: 1.2rem;
}
.form-label{
  color: var(--Tailwind-slate-950, #020617);
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.contact_form_manin_div__{
  padding: 6rem;
}

.contact_form_manin_div__ h1{
  color: #000;
font-family: Inter;
font-size: 2.25rem;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.045rem;
}
.get_icon{
  font-size: 3rem;
  color:white;
}
.get_bg{
  width: 120px;           /* Set the width of the circle */
  height: 120px;          /* Set the height of the circle */
  background-color:  #D1FAE5;
  border-radius: 50%;     /* Make the container a circle */
  display: flex;
  justify-content: end;   /* Align content to the end horizontally */
  align-items: center;    /* Center content vertically */
  padding-right: 10px;
}
.get_bg h1{
  color: var(--Tailwind-slate-950, #020617);
font-family: Inter;
font-size: 2.34506rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.footer_main_div{
  background-image: url('./assets/footer_bg.png');
  /* background-color: #044A36; */
  background-size: cover; 
  background-repeat: no-repeat; 
  background-position: bottom;
  padding: 0 1rem;
}
.footer_1 h3{
  color: var(--Tailwind-white, #FFF);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer_1 h4{
  color: var(--Tailwind-white, #FFF);
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 1.375rem; /* 157.143% */
}

.footer_1 h5{
  color: var(--Tailwind-white, #FFF);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 183.333% */
}

.footer_2 ul li{
  color: var(--Tailwind-white, #FFF);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.625rem 1rem;
  cursor: pointer;
  text-decoration: none;
}

.footer_3 h2{
  color: var(--Tailwind-white, #FFF);
font-family: Inter;
font-size: 2.34506rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.footer_3__ {
  justify-content: end;
}
.footer_3__ p{
  text-underline-offset: 4px;
  color: #FFF;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
opacity: 0.7;
}
.footer_botom p{
  color: var(--Tailwind-white, #FFF);
font-family: "Open Sans";
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 148.68%; /* 1.11513rem */
letter-spacing: 0.0125rem;
}
.nav_btn a{
  text-decoration: none;
  color: #000;
}
.custom_col{
  flex: 0 0 49%; 
  max-width: 48%;
}

.contact_btn_div{
  color: var(--Tailwind-black, #000);
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
height: 2.5rem;
padding: 0.625rem 1.25rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.5rem;
background: var(--Tailwind-yellow-400, #FACC15);
box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.12);
}





















.best_selling_product___ {
  position: relative;

}

.our_product_head_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.arrow-button {
  background: transparent;
  border: none;
  font-size: 1.5rem; /* Adjust size as needed */
  cursor: pointer;
  padding: 0 10px; /* Space around the arrow */
  z-index: 1; /* Ensure it's above the content */
  border: 2px solid #E2E8F0;
  border-radius: 5px;
  padding: 5px;
  font-size: 1.3rem;
 margin:0 5px ;
}

.product-container {
  display: flex;
  overflow-x: hidden; /* Hide scrollbar */
  scroll-behavior: smooth;
}



/* Hiding the scrollbar for webkit-based browsers */
.product-container::-webkit-scrollbar {
  display: none;
}

/* Hiding the scrollbar for other browsers */
.product-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.pagenum_div span{
  color: var(--Tailwind-slate-950, #020617);
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.025rem;
}


























/* Base Styles for Product Items */
.product-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory; /* Ensure smooth snapping */
  -webkit-overflow-scrolling: touch; /* Smooth scroll for mobile */
}

.product-item {
  display: flex;
  flex-shrink: 0;
  width: 100%; /* Each product takes full container width */
  scroll-snap-align: start; /* Align each product perfectly */
  box-sizing: border-box;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .product-item {
    flex-direction: column; /* Stack items vertically */
    gap: 0px;
    justify-content: center;
    width: 100%; /* Full viewport width for each item */
    margin: 0; /* No margin for mobile view */
  }
}

/* Product Items Inner Content */
.product-item > * {
  flex: 1; /* Ensure equal space for child elements */
  box-sizing: border-box;
}






/* Mobile styles */
@media (max-width: 576px) {
  .oru_product__{
    padding: 0 0.5rem;
  }

  .offer_div__{
    padding: 0 0.5rem;
  }
  
  .best_selling_product___{
    padding: 0 0.5rem;
  }

  .map_div_main{
    padding: 0 0.5rem;
 }

 .footer_main_div{
  background-image: url('./assets/footer_bg.png');
  
  padding: 0 0.5rem;
}

.navbar_main_div {
  padding: 0 0.5rem !important;
}

.list__ {
 font-size: 0.875rem;
 font-weight: 500;

}

.about_sta_main_div {
  margin: 0 0.5rem;
  border: 2px solid #E2E8F0;
  border-radius: 0.7rem;
  

}
.about_sta {
  padding: 0.75rem;
  
}
.about_sta_main_div h1 {
  font-size: 2rem;
  
}

.about_sta_main_div h2 {
  font-size: 1.25rem;
  padding-top: 1rem;
}

.about_sta_main_div h4 {
  font-size: 1rem;
}

.about_nav_btn {
 width: 100%;
 
}
.about_nav_btn p {
  color: var(--Tailwind-black, #000);
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;

}
.abou_sta_ico{
  font-size:1.25rem;
  font-weight: 900;
  
}

.organic_apple_main_div {
  padding: 1.5rem 1rem;
  flex-wrap: wrap;
  gap: 1rem;

}
.coffe_main_div {
  padding: 1.5rem 1rem;

}
.bread_main_div {
  padding: 1.5rem 1rem;

}


.our_product_head_div h1{
  color: #000;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.025rem;
}

.our_product_main_div{
  display: block;
  
}

.our_product_div{
  border: none;;
   
}
.best_selling_div_list_bread ul li {

  font-size: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;



}

.best_selling_div_list_bread ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%; 


}
.best_selling_div_list_bread {

  display: flex;
  flex-wrap: wrap; 
  gap: 1rem;


}

.best_selling_div_list_coffe ul li {

  font-size: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;


}

.best_selling_div_list_coffe ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%; 


}
.best_selling_div_list_coffe {

  display: flex;
  flex-wrap: wrap; 
  gap: 1rem;


}

.best_selling_div_list ul li {

  font-size: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  


}

.best_selling_div_list ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%; 


}
.best_selling_div_list {

  display: flex;
  flex-wrap: wrap; 
  gap: 1rem;


}
.contact_form_icons_div {
  padding: 1rem;
  }
  .custom_col{
    flex: 100%; 
    max-width: 100%;
  }

  .contact_form_manin_div__{
    padding: 1rem;
  }
  .contact_form_div{
    margin: 0;
    border-radius: 0;
    background-image: url('./assets/contact_form_vector.png');
    border-bottom: 2px solid #E5E7EB;

  }
  .footer_main_div{
    background: #008760;
  }
  .footer_2 ul li{
    padding: 0.75rem 0rem;
    line-height: normal;
    font-size: 1rem;
  }

  .get_bg{
    width: 100px;           /* Set the width of the circle */
    height: 100px;          /* Set the height of the circle */
    background-color:  #D1FAE5;
    border-radius: 50%;     /* Make the container a circle */
    display: flex;
    justify-content: end;   /* Align content to the end horizontally */
    align-items: center;    /* Center content vertically */
    padding-right: 10px;
  }
  .get_bg h1{
    font-size: 2.10094rem;
  }
  .footer_3 h2{
    font-size: 2.10094rem;
  }

  .footer_3__ {
    justify-content: center;
  }
  .h1__contact h1{
    color: #000;
font-family: Inter;
font-size: 2.25rem;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.045rem;
  }

  .content_form h1{
    color: var(--Tailwind-slate-600, #475569);
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.0175rem;
  }
  
  .content_form h2{
    color: var(--Tailwind-slate-950, #020617);
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.375rem; /* 137.5% */
  }

  .ReCAPTCHA__div{
    width: 50px;
  transform: scale(0.7); /* Adjust the scaling as needed */
  transform-origin: 0 0; /* Ensures scaling from the top-left */
  }
  .contact_btn_div{
    color: var(--Tailwind-black, #000);
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
height: 2.5rem;
padding: 0.625rem 1.25rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
align-self: stretch;
border-radius: 0.5rem;
background: var(--Tailwind-yellow-400, #FACC15);
box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.12);
  }

  .carousel-container__cr4  {
    padding: 0 0.75rem 0.75rem 0.75rem !important;
  
  }
  .carousel-container__cr4 .carousel {
    border-radius: 0.7rem;
    
  }

 

  .pagenum_div span{
    font-size: 0.875rem;
  }
  .arrow-button {
    background: transparent;
    border: none;
    font-size: 1.5rem; /* Adjust size as needed */
    cursor: pointer;
    padding: 0 10px; /* Space around the arrow */
    z-index: 1; /* Ensure it's above the content */
    border: 2px solid #E2E8F0;
    border-radius: 5px;
    padding: 3px;
    font-size: 1rem;
  
  }
  
}
  


