.carousel .control-dots {
    display: flex;
    justify-content: end;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  
  .carousel .control-dots .dot {
    margin: 0 5px;
  }
  .carousel-container{
    padding: 0 0.6rem;
    
  }

  .carousel{
    border-radius: 0.7rem;
  }

  .home_carousel{
    
  }